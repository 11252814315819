var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.links && (_vm.links.loading || _vm.links.items.length > 0)
    ? _c(
        "div",
        [
          _c("v-card-subtitle", { staticClass: "font-italic px-0" }, [
            _vm._v(
              "\n    Link warnings (yellow) indicate that the NOWW Platform was unable to verify the link."
            ),
            _c("br"),
            _vm._v(
              "\n    Review yellow link(s) below to verify each link is working as desired."
            ),
            _c("br"),
            _vm._v("\n    Link warnings(yellow) are "),
            _c("strong", [_vm._v("not")]),
            _vm._v(" required to be valid to send a broadcast."),
            _c("br"),
            _c("br"),
            _vm._v("\n    Link errors (red) "),
            _c("strong", [_vm._v("need")]),
            _vm._v(" to be fixed before sending.\n  ")
          ]),
          _vm.links && !_vm.links.loading
            ? _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _c("div", { staticClass: "d-flex justify-center" }, [
                    _vm.links.totalInvalid > 0
                      ? _c(
                          "span",
                          { staticClass: "text-h6 font-weight-bold" },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.links.totalInvalid) +
                                "/" +
                                _vm._s(_vm.links.items.length) +
                                " Invalid\n      "
                            )
                          ]
                        )
                      : _c(
                          "span",
                          { staticClass: "text-h6 font-weight-bold" },
                          [
                            _vm._v(
                              "\n        All " +
                                _vm._s(_vm.links.items.length) +
                                " Valid\n      "
                            )
                          ]
                        )
                  ]),
                  _c(
                    "v-list",
                    { attrs: { "two-line": "" } },
                    [
                      _vm._l(_vm.displayItems, function(item, index) {
                        return [
                          item.divider
                            ? _c("v-divider", {
                                key: index,
                                attrs: { inset: "" }
                              })
                            : _c(
                                "v-list-item",
                                { key: index, staticClass: "px-0" },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            color: item.iconColor,
                                            "x-large": ""
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(item.icon) +
                                              "\n            "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              attrs: {
                                                right: "",
                                                "x-offset": "",
                                                origin: "right",
                                                transition:
                                                  "slide-x-transition",
                                                "close-on-content-click": false,
                                                "close-on-click": false,
                                                width: _vm.menuWidth
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var onMenu = ref.on
                                                      return [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: { top: "" },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var onTooltip =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "text--primary"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-chip",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  color:
                                                                                    item.iconColor
                                                                                }
                                                                              },
                                                                              Object.assign(
                                                                                {},
                                                                                onMenu,
                                                                                onTooltip
                                                                              )
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                "\n                          " +
                                                                                  _vm._s(
                                                                                    item.href
                                                                                  ) +
                                                                                  "\n                        "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Edit link"
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: item.linkMenu,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "linkMenu",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.linkMenu"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-card",
                                                [
                                                  _c(
                                                    "v-card-text",
                                                    {
                                                      staticClass: "px-5 py-2"
                                                    },
                                                    [
                                                      _c(
                                                        "v-container",
                                                        [
                                                          _c(
                                                            "v-row",
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "py-0",
                                                                  attrs: {
                                                                    cols: "12"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        "error-messages":
                                                                          _vm.hrefErrors,
                                                                        required:
                                                                          "",
                                                                        label:
                                                                          "Url"
                                                                      },
                                                                      on: {
                                                                        input: function(
                                                                          $event
                                                                        ) {
                                                                          item.href
                                                                            ? (_vm.hrefErrors = null)
                                                                            : (_vm.hrefErrors =
                                                                                "Url is required")
                                                                        },
                                                                        keyup: function(
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          ) {
                                                                            return null
                                                                          }
                                                                          return _vm.saveLink(
                                                                            item
                                                                          )
                                                                        }
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "append",
                                                                            fn: function() {
                                                                              return [
                                                                                _c(
                                                                                  "v-tooltip",
                                                                                  {
                                                                                    attrs: {
                                                                                      top:
                                                                                        ""
                                                                                    },
                                                                                    scopedSlots: _vm._u(
                                                                                      [
                                                                                        {
                                                                                          key:
                                                                                            "activator",
                                                                                          fn: function(
                                                                                            ref
                                                                                          ) {
                                                                                            var on =
                                                                                              ref.on
                                                                                            return [
                                                                                              _c(
                                                                                                "v-btn",
                                                                                                _vm._g(
                                                                                                  {
                                                                                                    attrs: {
                                                                                                      href:
                                                                                                        item.href,
                                                                                                      disabled: !item.href,
                                                                                                      small:
                                                                                                        "",
                                                                                                      icon:
                                                                                                        "",
                                                                                                      color:
                                                                                                        "info",
                                                                                                      target:
                                                                                                        "_blank"
                                                                                                    }
                                                                                                  },
                                                                                                  on
                                                                                                ),
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-icon",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "mdi-link"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            ]
                                                                                          }
                                                                                        }
                                                                                      ],
                                                                                      null,
                                                                                      true
                                                                                    )
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "Go to page"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            },
                                                                            proxy: true
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                      model: {
                                                                        value:
                                                                          item.href,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "href",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "item.href"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "py-0",
                                                                  attrs: {
                                                                    cols: "12"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "Link Text"
                                                                      },
                                                                      on: {
                                                                        keyup: function(
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          ) {
                                                                            return null
                                                                          }
                                                                          return _vm.saveLink(
                                                                            item
                                                                          )
                                                                        }
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          item.text,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "text",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "item.text"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "py-0",
                                                                  attrs: {
                                                                    cols: "12"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "Link Name",
                                                                        "persistent-hint":
                                                                          "",
                                                                        hint:
                                                                          "Link Name is used to identify click results in reports"
                                                                      },
                                                                      on: {
                                                                        keyup: function(
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          ) {
                                                                            return null
                                                                          }
                                                                          return _vm.saveLink(
                                                                            item
                                                                          )
                                                                        }
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          item.name,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "name",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "item.name"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-divider"),
                                                  _c(
                                                    "v-card-actions",
                                                    [
                                                      _c("v-spacer"),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "tertiary",
                                                            disabled:
                                                              _vm.loading
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.closeLinkMenu(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      Cancel\n                    "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "success",
                                                            loading: _vm.loading
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.saveLink(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      Save\n                    "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-subtitle", [
                                        item.text
                                          ? _c(
                                              "span",
                                              { staticClass: "text--primary" },
                                              [_vm._v(_vm._s(item.text))]
                                            )
                                          : _vm._e(),
                                        item.text ? _c("br") : _vm._e(),
                                        _c(
                                          "span",
                                          { staticClass: "text--primary" },
                                          [
                                            _vm._v(
                                              _vm._s(item.statusCode) + " - "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "grey--text font-italic"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(item.statusDescription)
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              )
            : _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _c("v-skeleton-loader", {
                    attrs: { type: "card-heading, list-item-avatar@5" }
                  })
                ],
                1
              )
        ],
        1
      )
    : _c(
        "div",
        [
          _c("v-card-title", { staticClass: "font-italic" }, [
            _vm._v("\n    No links found\n  ")
          ])
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }