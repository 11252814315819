<template>
  <div
    v-if="links && (links.loading || links.items.length > 0)"
  >
    <v-card-subtitle
      class="font-italic px-0"
    >
      Link warnings (yellow) indicate that the NOWW Platform was unable to verify the link.<br>
      Review yellow link(s) below to verify each link is working as desired.<br>
      Link warnings(yellow) are <strong>not</strong> required to be valid to send a broadcast.<br><br>
      Link errors (red) <strong>need</strong> to be fixed before sending.
    </v-card-subtitle>
    <v-card-text
      v-if="links && !links.loading"
      class="pa-0"
    >
      <div class="d-flex justify-center">
        <span
          v-if="links.totalInvalid > 0"
          class="text-h6 font-weight-bold"
        >
          {{ links.totalInvalid }}/{{ links.items.length }} Invalid
        </span>
        <span
          v-else
          class="text-h6 font-weight-bold"
        >
          All {{ links.items.length }} Valid
        </span>
      </div>
      <v-list
        two-line
      >
        <template v-for="(item, index) in displayItems">
          <v-divider
            v-if="item.divider"
            :key="index"
            inset
          />

          <v-list-item
            v-else
            :key="index"
            class="px-0"
          >
            <v-list-item-avatar>
              <v-icon
                :color="item.iconColor"
                x-large
              >
                {{ item.icon }}
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                <v-menu
                  v-model="item.linkMenu"
                  right
                  x-offset
                  origin="right"
                  transition="slide-x-transition"
                  :close-on-content-click="false"
                  :close-on-click="false"
                  :width="menuWidth"
                >
                  <template v-slot:activator="{ on: onMenu }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on: onTooltip }">
                        <span class="text--primary">
                          <v-chip
                            :color="item.iconColor"
                            v-on="{ ...onMenu, ...onTooltip }"
                          >
                            {{ item.href }}
                          </v-chip>
                        </span>
                      </template>
                      <span>Edit link</span>
                    </v-tooltip>
                  </template>
                  <v-card>
                    <v-card-text class="px-5 py-2">
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="item.href"
                              :error-messages="hrefErrors"
                              required
                              label="Url"
                              @input="item.href ? hrefErrors = null : hrefErrors = 'Url is required'"
                              @keyup.enter="saveLink(item)"
                            >
                              <template v-slot:append>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      :href="item.href"
                                      :disabled="!item.href"
                                      small
                                      icon
                                      color="info"
                                      target="_blank"
                                      v-on="on"
                                    >
                                      <v-icon>mdi-link</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Go to page</span>
                                </v-tooltip>
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="item.text"
                              label="Link Text"
                              @keyup.enter="saveLink(item)"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="item.name"
                              label="Link Name"
                              persistent-hint
                              hint="Link Name is used to identify click results in reports"
                              @keyup.enter="saveLink(item)"
                            />
                          </v-col>
                          <!-- <v-col
                            cols="12"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="item.id"
                              label="Id"
                              @keyup.enter="saveLink(item)"
                            />
                          </v-col> -->
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="tertiary"
                        :disabled="loading"
                        @click="closeLinkMenu(item)"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="success"
                        :loading="loading"
                        @click="saveLink(item)"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-list-item-title>
              <v-list-item-subtitle>
                <span
                  v-if="item.text"
                  class="text--primary"
                >{{ item.text }}</span>
                <br v-if="item.text">
                <span class="text--primary">{{ item.statusCode }} - </span><span class="grey--text font-italic">{{ item.statusDescription }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-card-text>
    <v-card-text
      v-else
      class="pa-0"
    >
      <v-skeleton-loader type="card-heading, list-item-avatar@5" />
    </v-card-text>
  </div>
  <div
    v-else
  >
    <v-card-title class="font-italic">
      No links found
    </v-card-title>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { azBlobService } from '@/shared/services'

export default {
  name: 'LinkChecker',

  mixins: [validationMixin],

  props: {
    splitId: {
      type: String,
      default: null
    },
    links: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({
    menuWidth: 500,
    hrefErrors: null,
    loading: false
  }),

  computed: {
    displayItems () {
      return this.links.items.length > 0 ? [...this.links.items].map((e, i) => i < this.links.items.length - 1 ? [e, { divider: true }] : [e]).reduce((a, b) => a.concat(b)) : this.links.items
    },
    linkRatingBackgroundColor () {
      if (!this.links.items || this.links.totalInvalid > 0) return 'grey'
      return 'success'
    }
  },

  methods: {
    closeLinkMenu (item) {
      item.linkMenu = false
      this.hrefErrors = null
    },
    async saveLink (item) {
      // validate
      if (!item || !item.href) {
        this.hrefErrors = 'Url is required'
        return
      }
      // call api to save
      this.loading = true
      var resp = await azBlobService.setSplitLink(this.splitId, item)
      this.loading = false
      // emit refresh of creative/validate links
      this.$emit('load-split-html')
      this.$emit('set-link-validation', resp)
      this.closeLinkMenu(item)
    }
  }
}
</script>
